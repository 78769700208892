.study-browser {
  float: left;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #0c5fa7;
  padding-bottom: 20px;
  padding-top: 10px;
}
.study-browser .scrollable-study-thumbnails {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
  padding-right: 16px;
  padding-left: 4px;
  margin-right: -16px;
  display: flex;
  flex-direction: column;
/* Track */
/* Handle */
/* Handle on hover */
}
.study-browser .scrollable-study-thumbnails::-webkit-scrollbar {
  width: 25px;
  margin-right: 5px;
  cursor: grab;
}
.study-browser .scrollable-study-thumbnails::-webkit-scrollbar-track {
  border-radius: 5px;
}
.study-browser .scrollable-study-thumbnails::-webkit-scrollbar-thumb {
  background: #c4e4ff;
  border-radius: 5px;
}
.study-browser .scrollable-study-thumbnails::-webkit-scrollbar-thumb:hover {
  background: #c4e4ff;
}
.study-browser .scrollable-study-thumbnails .thumbnail-container {
  margin: 0 auto;
  padding-bottom: 1.5rem;
}
.study-browser .scrollable-study-thumbnails .thumbnail-container .noselect {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.draggable {
  cursor: copy;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
